import React from 'react';
import '../App.css';

function Support() {
  return (
    <div className="container">
      <h2>Support</h2>
      <p>
        Contact support by emailing:{' '}
        <a href="mailto:scott.carnegie@tidbytes.ca">
          scott.carnegie@tidbytes.ca
        </a>
      </p>
    </div>
  );
}

export default Support;
